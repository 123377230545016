import { MoreVert as MoreVertIcon } from "@mui/icons-material";
import { Switch } from "@mui/material";
import { useState } from "react";
import useTranslation from "../../hooks/useTranslation";
import DesertImg from "../../styles/illustrations/Desert.png";
import { QuestionType } from "../../types/question";
import { QuestionBreakdown as IQuestionBreakdown } from "../../types/workplaceCulture";
import { NoDataState } from "../NoDataState/NoDataState";
import { QuestionBreakdownChart } from "../QuestionBreakdownChart/QuestionBreakdownChart";
import { Button } from "../ui/Button/Button";
import { Menu } from "../ui/Menu/Menu";
import { Typography } from "../ui/Typography/Typography";
import "./QuestionBreakdown.scss";

interface QuestionBreakdownProps {
  questionBreakdown: IQuestionBreakdown;
  questionHasAnswers: boolean;
  selectedFilters: number[];
  onFilterClick: (index: number) => void;
}

export const QuestionBreakdown = ({
  questionBreakdown,
  questionHasAnswers,
  selectedFilters = [0],
  onFilterClick,
}: QuestionBreakdownProps) => {
  const { t } = useTranslation("workplaceCulturePage");
  const [showIsSkip, setShowIsSkip] = useState(false);
  const [useCombinedOptions, setShowCombinedOptions] = useState(true);
  const [actionsAnchor, setActionsAnchor] = useState<HTMLElement>();
  const handleOpenMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    setActionsAnchor(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setActionsAnchor(undefined);
  };

  const menuItems = [
    {
      text: showIsSkip
        ? t("chapterPage.breakdown.actions.toggleIsSkipOn")
        : t("chapterPage.breakdown.actions.toggleIsSkipOff"),
      icon: (
        <Switch
          checked={showIsSkip}
          onClick={() => setShowIsSkip(!showIsSkip)}
          data-testid="breakdown-toggle-is-skip"
        />
      ),
    },
    {
      text: useCombinedOptions
        ? t("chapterPage.breakdown.actions.toggleCombinedOn")
        : t("chapterPage.breakdown.actions.toggleCombinedOff"),
      icon: (
        <Switch
          checked={useCombinedOptions}
          onClick={() => setShowCombinedOptions(!useCombinedOptions)}
          data-testid="breakdown-toggle-is-combined"
        />
      ),
    },
  ];

  return (
    <div className="QuestionBreakdown">
      <div className="QuestionBreakdown__title">
        <Typography desktop="h3">
          {t("chapterPage.breakdown.answers.title")}
        </Typography>
        {questionBreakdown.kind !== QuestionType.MultiSelect && (
          <Button
            onClick={handleOpenMenu}
            data-testid="breakdown-chart-actions"
            aria-label="more actions on inclusion scale"
            aria-controls="breakdown-chart-actions"
            aria-haspopup="true"
            variant="outlined"
            color="secondary"
            small
            icon
            disabled={!questionHasAnswers}
          >
            <MoreVertIcon />
          </Button>
        )}

        <Menu
          id="more-actions-menu"
          anchorEl={actionsAnchor}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: 52,
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={!!actionsAnchor}
          onClose={handleCloseMenu}
          menuItems={menuItems}
        />
      </div>

      <div className="QuestionBreakdown__filters">
        {questionBreakdown.filters.map((filter, i) => (
          <Button
            key={`${filter.label}-${i}`}
            className="QuestionBreakdown__filters__button"
            onClick={() => onFilterClick(i)}
            color="secondary"
            small
            variant={selectedFilters.includes(i) ? "contained" : "outlined"}
          >
            {filter.label}
          </Button>
        ))}
      </div>
      {questionHasAnswers &&
        selectedFilters.map((filterIndex) => {
          const filter = questionBreakdown.filters[filterIndex];

          return (
            <QuestionBreakdownChart
              breakdownFilter={filter}
              useCombinedOptions={useCombinedOptions}
              showIsSkip={showIsSkip}
              key={`question-breakdown-chart-${filter.label}-${filterIndex}`}
            />
          );
        })}
      {questionHasAnswers && selectedFilters.length === 0 && (
        <NoDataState
          className="QuestionBreakdown__noSelectedFilters"
          info={t("chapterPage.breakdown.noFiltersSelected")}
          imgSrc={DesertImg}
        />
      )}
      {!questionHasAnswers && (
        <NoDataState
          className="QuestionBreakdown__noAnswer"
          info={t("chapterPage.breakdown.answers.noAnswers")}
          light
        />
      )}
    </div>
  );
};
