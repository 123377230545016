import useTranslation from "../../hooks/useTranslation";
import { QuestionBreakdownFilter } from "../../types/workplaceCulture";
import { NoDataState } from "../NoDataState/NoDataState";
import { Typography } from "../ui/Typography/Typography";

interface QuestionBreakdownNoDataProps {
  breakdownFilter: QuestionBreakdownFilter;
  customMessage?: string;
}

export const QuestionBreakdownNoData = ({
  breakdownFilter,
  customMessage,
}: QuestionBreakdownNoDataProps) => {
  const { t } = useTranslation("workplaceCulturePage");

  return (
    <div className="QuestionBreakdownBreakdownNoData">
      <Typography
        className="QuestionBreakdownBreakdownNoData__title"
        desktop="body2"
        weight="bold"
      >
        {breakdownFilter.label}
      </Typography>
      <NoDataState
        className="QuestionBreakdownBreakdownNoData__info"
        info={
          customMessage ? customMessage : t("surveyDemographics.notEnoughData")
        }
        light
      />
    </div>
  );
};
