import {
  dampCyanColor,
  darkStoneBlue,
  darkVistaBlue,
  eggplantColor,
  keyLimeColor,
  olivineColor,
  orangeColor,
  pennBlueColor,
  roseOrange,
  vistaBlueColor,
} from "../styles/theme";

export const workplaceCultureColors = [
  pennBlueColor,
  dampCyanColor,
  vistaBlueColor,
  orangeColor,
  roseOrange,
];

export const questionBreakdownColors = [
  darkStoneBlue,
  keyLimeColor,
  dampCyanColor,
  eggplantColor,
  orangeColor,
  roseOrange,
  olivineColor,
  darkVistaBlue,
];

export const graphColors = [
  eggplantColor,
  dampCyanColor,
  vistaBlueColor,
  orangeColor,
  roseOrange,
  olivineColor,
  keyLimeColor,
  darkVistaBlue,
];

const getColorByScore = (score: number) => {
  if (score > 1 && score <= 2) return workplaceCultureColors[1];
  if (score > 2 && score <= 3) return workplaceCultureColors[2];
  if (score > 3 && score <= 4) return workplaceCultureColors[3];
  if (score > 4 && score <= 5) return workplaceCultureColors[4];
  return workplaceCultureColors[0];
};

export default getColorByScore;
