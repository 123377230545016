import { VisibilityOffOutlined as VisibilityOffOutlinedIcon } from "@mui/icons-material";
import classNames from "classnames";
import { Typography } from "../ui/Typography/Typography";
import "./NoDataState.scss";

interface NoDataStateProps {
  className?: string;
  info: string;
  light?: boolean;
  title?: string;
  imgSrc?: string;
}

export const NoDataState = ({
  className,
  info,
  light,
  title,
  imgSrc,
}: NoDataStateProps) => {
  const classes = classNames("NoDataState", className, {
    "NoDataState--light": light,
  });

  return (
    <div className={classes}>
      <div className="NoDataState__icon">
        {imgSrc ? (
          <img src={imgSrc} alt="No Data" className="NoDataState__img" />
        ) : (
          <VisibilityOffOutlinedIcon fontSize={light ? "medium" : "large"} />
        )}
      </div>
      <div className="NoDataState__text">
        <Typography tagVariant="h2" desktop="h2">
          {title}
        </Typography>
        <Typography
          tagVariant="p"
          desktop="body2"
          className="NoDataState__text__info"
          color={light ? "primary" : "secondary"}
        >
          {info}
        </Typography>
      </div>
    </div>
  );
};
