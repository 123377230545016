import { QuestionType } from "../types/question";
import {
  QuestionBreakdown,
  QuestionBreakdownFilter,
  QuestionBreakdownFilterOptions,
} from "../types/workplaceCulture";
import { questionBreakdownColors } from "./graphs";

const LEGEND_WIDTH = 120;

export const getGroupedResponses = (
  chapterQuestionResponses: QuestionBreakdown[],
  useCombinedOptions = true
): {
  [key: string]: QuestionBreakdown[];
} => {
  return chapterQuestionResponses.reduce(
    (acc: { [key: string]: QuestionBreakdown[] }, questionBreakdown) => {
      const isMultiSelectQuestion =
        questionBreakdown.kind === QuestionType.MultiSelect;
      const key = isMultiSelectQuestion
        ? questionBreakdown.text
        : questionBreakdown.preamble || "";

      if (!acc[key]) {
        acc[key] = [];
      }

      if (isMultiSelectQuestion) {
        // We want to show each option of a MultiSelectQuestion as a separate question
        // So we map the answers to a question format so we can display them in the tabs
        let options: QuestionBreakdownFilterOptions[] | undefined;
        for (const filter of questionBreakdown.filters) {
          if (filter.groups?.length !== 0) {
            options =
              filter.groups?.[0][
                useCombinedOptions ? "combined_options" : "options"
              ];
            break;
          }
        }

        const multiAnswerQuestions = options?.map((answer) => {
          const filteredFilters = getOptionFilters(
            questionBreakdown.filters,
            useCombinedOptions
              ? answer.option_ids?.[0] || ""
              : answer.reference_id || ""
          );

          const answerMappedToQuestion: QuestionBreakdown = {
            text: answer.text,
            reference_id: useCombinedOptions
              ? answer.option_ids?.[0] || ""
              : answer.reference_id || "",
            kind: questionBreakdown.kind,
            filters: filteredFilters,
            total_responses: questionBreakdown.total_responses,
          };

          return answerMappedToQuestion;
        });

        multiAnswerQuestions?.forEach((question) => {
          acc[key].push(question);
        });
      } else {
        acc[key].push(questionBreakdown);
      }

      return acc;
    },
    {}
  );
};

export const getOptionFilters = (
  filters: QuestionBreakdownFilter[],
  answerReferenceId: string
): QuestionBreakdownFilter[] => {
  return filters.map((filter) => {
    const newGroups = filter.groups?.map((group) => {
      const newOptions = group.options.filter(
        (option) => option.reference_id === answerReferenceId
      );

      const newCombinedOptions = group.combined_options.filter((option) =>
        option.option_ids.includes(answerReferenceId)
      );

      return {
        label: group.label,
        engagement: group.engagement,
        options: newOptions,
        combined_options: newCombinedOptions,
      };
    });
    return {
      label: filter.label,
      groups: newGroups,
      is_custom: filter.is_custom,
    };
  });
};

export const doesQuestionHaveAnswers = (
  questionBreakdown?: QuestionBreakdown
) => {
  if (questionBreakdown) {
    for (const filter of questionBreakdown.filters) {
      if (Array.isArray(filter.groups)) {
        for (const group of filter.groups) {
          const options = group.options || [];
          const combinedOptions = group.combined_options || [];
          const allOptions = [...options, ...combinedOptions];
          const hasAnswers = allOptions.some((option) => option.count !== null);
          if (hasAnswers) {
            return true;
          }
        }
      }
    }
  }
  return false;
};

export const updateFilters = (selectedFilters: number[], index: number) => {
  if (selectedFilters.includes(index)) {
    return [...selectedFilters.filter((filterIndex) => filterIndex !== index)];
  } else {
    return [...selectedFilters, index].sort();
  }
};

export const getQuestionResponseChartData = (
  t: any,
  breakdownFilter?: QuestionBreakdownFilter,
  useCombinedOptions = true,
  showIsSkip = false
): Highcharts.Options => {
  const { series, categories, skippedGroups } = transformQuestionToChartSeries(
    breakdownFilter,
    useCombinedOptions,
    showIsSkip
  );

  // Compute the highest count from the series data
  let computedMax = 0;
  if (series && series.length) {
    // Flatten all 'data' arrays into an array of y-values
    const dataVals = series.flatMap((s) =>
      (s.data as Array<{ y: number }>).map((point) => point.y)
    );

    if (dataVals.length) {
      computedMax = Math.max(...dataVals);
    }
  }

  // Build a subtitle listing any skipped groups

  let skippedTitle = "";
  if (skippedGroups.length > 0) {
    skippedTitle =
      t("chapterPage.breakdown.chart.subtitleSkipped") +
      " " +
      skippedGroups.join(", ");
  }

  let subtitleText = "";
  if (computedMax < 50) {
    subtitleText = t("chapterPage.breakdown.chart.subtitleScalingNote");
  }

  let yAxisMax = 100;
  if (computedMax < 50 && computedMax >= 15) {
    yAxisMax = 50;
  } else if (computedMax < 15) {
    yAxisMax = 15;
  }

  const options: Highcharts.Options = {
    title: {
      text: breakdownFilter?.label,
      align: "left",
      style: {
        fontSize: "14px",
        fontFamily: "inherit",
        fontWeight: "bold",
      },
    },
    subtitle: {
      text: subtitleText,
      align: "right",
    },
    chart: {
      type: "column",
      height: 300,
      zooming: {
        type: "y",
        resetButton: {
          position: {
            align: "right",
            verticalAlign: "top",
          },
        },
      },
    },
    xAxis: {
      categories,
      title: {
        text: skippedTitle,
        align: "high",
        x: LEGEND_WIDTH + 20,
      },
    },
    yAxis: {
      min: 0,
      max: yAxisMax,
      gridLineWidth: 0,
      title: {
        text: t("chapterPage.breakdown.chart.yAxisTitle"),
      },
    },
    legend: {
      enabled: true,
      align: "right",
      verticalAlign: "middle",
      width: LEGEND_WIDTH,
      itemStyle: {
        textOverflow: "wrap",
      },
    },
    plotOptions: {
      column: {
        pointWidth: 20,
        dataLabels: {
          enabled: true,
          formatter: function () {
            return `${Math.round(this.y as number)}%`;
          },
          style: {
            fontWeight: "400",
          },
        },
      },
    },
    tooltip: {
      formatter: function () {
        const rawCount = (this.point as any).options?.rawCount || 0;
        const n = (this.point as any).options?.n || 0;
        return `<b>${this.series.name} - ${Math.round(
          this.y as number
        )}%</b><br>
              n = ${n}<br>
              ${t("chapterPage.breakdown.chart.rawCount")} = ${rawCount}<br>`;
      },
    },
    series: series,
  };

  return options;
};

export const transformQuestionToChartSeries = (
  breakdownFilter?: QuestionBreakdownFilter,
  useCombinedOptions = true,
  showIsSkip = false
): {
  series: Highcharts.SeriesColumnOptions[];
  categories: string[];
  skippedGroups: string[];
} => {
  if (!breakdownFilter) {
    return { series: [], categories: [], skippedGroups: [] };
  }

  const groups = breakdownFilter.groups || [];

  // Filter out any groups that have null total in all non-skip options,
  // that means that group did not reach the threshold
  const skippedGroups: string[] = [];
  const groupsWithValues = groups.filter((group) => {
    const options: QuestionBreakdownFilterOptions[] = useCombinedOptions
      ? group.combined_options
      : group.options;
    const hasValues = options.some((option) => {
      if (showIsSkip) {
        return option.count !== null;
      } else {
        return option.count !== null && !option.is_skip;
      }
    });
    if (!hasValues) {
      skippedGroups.push(group.label);
    }
    return hasValues;
  });

  // Collect all unique non-skip combined option texts from the filtered groups
  const uniqueTexts = new Set<string>();
  groupsWithValues.forEach((group) => {
    const options: QuestionBreakdownFilterOptions[] = useCombinedOptions
      ? group.combined_options
      : group.options;
    const relevantOptions = showIsSkip
      ? options
      : options.filter((o) => !o.is_skip);

    relevantOptions.forEach((option) => {
      uniqueTexts.add(option.text);
    });
  });

  const categories = Array.from(uniqueTexts);

  // Build one series per filtered group
  const series: Highcharts.SeriesColumnOptions[] = groupsWithValues.map(
    (group, i) => {
      const data = categories.map((text) => {
        const options: QuestionBreakdownFilterOptions[] = useCombinedOptions
          ? group.combined_options
          : group.options;

        const match = showIsSkip
          ? options.find((opt) => opt.text === text)
          : options.find((opt) => opt.text === text && !opt.is_skip);

        const groupEngagement = group.engagement || 1;

        const rawCount = match && match.count ? match.count : 0;
        const percentage = (rawCount / groupEngagement) * 100;
        const n = groupEngagement;

        return {
          y: percentage,
          rawCount,
          n,
        };
      });

      return {
        type: "column",
        name: group.label,
        color: questionBreakdownColors[i],
        data,
      };
    }
  );

  return { series, categories, skippedGroups };
};
