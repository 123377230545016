import { Divider, Skeleton } from "@mui/material";
import classnames from "classnames";
import { useEffect, useState } from "react";
import useTranslation from "../../hooks/useTranslation";
import {
  QuestionBreakdown as IQuestionBreakdown,
  QuestionBreakdownFilter,
} from "../../types/workplaceCulture";
import {
  doesQuestionHaveAnswers,
  getGroupedResponses,
} from "../../utils/QuestionBreakdown.utils";
import { updateFilters } from "../../utils/QuestionBreakdown.utils";
import { QuestionBreakdown } from "../QuestionBreakdown/QuestionBreakdown";
import { TabPanel } from "../ui/Tabs/TabPanel";
import { Tabs } from "../ui/Tabs/Tabs";
import { Typography } from "../ui/Typography/Typography";
import { QuestionBreakdownTab } from "./QuestionBreakdownTab";
import "./QuestionBreakdownOverview.scss";

export interface QuestionBreakdownProps {
  questionBreakdownForChapter: IQuestionBreakdown[];
  isLoading: boolean;
  error: boolean;
}
export const QuestionBreakdownOverview = ({
  questionBreakdownForChapter,
  isLoading = true,
  error = false,
}: QuestionBreakdownProps) => {
  const { t } = useTranslation("workplaceCulturePage");
  const [groupedResponses, setGroupedResponses] = useState<{
    [key: string]: IQuestionBreakdown[];
  }>({});
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  // Keeping selectedFilters here so that the filters are not reset when
  // switching between questions
  const [selectedFilters, setSelectedFilters] = useState<number[]>([0]);

  useEffect(() => {
    // Add total_responses to the filter field so it appears as a filter bubble
    // and the options of it are filtered like the others
    questionBreakdownForChapter.forEach((questionBreakdown) => {
      const filters = questionBreakdown.filters;

      // check if total_responses label is already in the filters
      const totalResponsesLabelExists = filters.some(
        (filter) => filter.label === questionBreakdown.total_responses.label
      );

      if (totalResponsesLabelExists) {
        return;
      }

      // add the total_responses to the filters at the first index
      const totalResponsesFilter: QuestionBreakdownFilter = {
        label: questionBreakdown.total_responses.label,
        groups: [
          {
            label: questionBreakdown.total_responses.label,
            engagement: questionBreakdown.total_responses.engagement,
            options: questionBreakdown.total_responses.options,
            combined_options:
              questionBreakdown.total_responses.combined_options,
          },
        ],
        is_custom: false,
      };
      filters.unshift(totalResponsesFilter);

      questionBreakdown.filters = filters;
    });

    const groupedResponses = getGroupedResponses(
      questionBreakdownForChapter,
      false
    );
    setGroupedResponses(groupedResponses);
  }, [questionBreakdownForChapter]);

  const handleTabChange = (newValue: number) => {
    setSelectedTabIndex(newValue);
  };

  const filterClickHandler = (index: number) => {
    setSelectedFilters(updateFilters(selectedFilters, index));
  };

  const renderQuestions = () => {
    let tabCount = 0; //Needed because tabs are grouped, but we want to count them linearly
    return (
      <Tabs
        selectedTabIndex={selectedTabIndex}
        label="workplace-culture-chapter-question-breakdown"
        orientation="vertical"
        className={"QuestionBreakdownOverview__content__questions__tabs"}
        variant="standard"
        scrollButtons={false}
        allowScrollButtonsMobile={false}
      >
        {Object.keys(groupedResponses).map((groupKey) => {
          const questionResponses = groupedResponses[groupKey];

          const tabClasses = classnames(
            "QuestionBreakdownOverview__content__questions__tab",
            {
              "QuestionBreakdownOverview__content__questions__tab--grouped":
                !!groupKey,
            }
          );

          return questionResponses.map((questionResponse, i) => {
            const currentTabCount = tabCount;
            tabCount = tabCount + 1;
            return (
              <QuestionBreakdownTab
                isFirstInGroup={i === 0}
                groupKey={groupKey}
                tabClasses={tabClasses}
                questionResponse={questionResponse}
                selected={selectedTabIndex === currentTabCount}
                tabIndex={currentTabCount}
                handleTabChange={handleTabChange}
              />
            );
          });
        })}
      </Tabs>
    );
  };

  let tabCount = 0;
  return (
    <div className="QuestionBreakdownOverview">
      <Typography desktop="handwrittenH1" color="error">
        {t("chapterPage.breakdown.title")}
      </Typography>
      <Typography
        desktop="body1"
        className="QuestionBreakdownOverview__info"
        color="secondary"
      >
        {t("chapterPage.breakdown.info")}
      </Typography>
      {!error && (
        <div className="QuestionBreakdownOverview__content">
          <div className="QuestionBreakdownOverview__content__questions">
            <Typography desktop="h2">
              {t("chapterPage.breakdown.questions.title")}
            </Typography>
            <Divider className="QuestionBreakdownOverview__content__questions__divider" />
            {isLoading && <Skeleton sx={{ mr: 4 }} />}
            {renderQuestions()}
          </div>
          <div className="QuestionBreakdownOverview__content__answers">
            {isLoading && <Skeleton sx={{ mt: 5.5, mr: 4 }} />}
            {Object.keys(groupedResponses).map((groupKey) => {
              const questionResponses = groupedResponses[groupKey];

              return questionResponses.map((questionResponse) => {
                const questionHasAnswers =
                  doesQuestionHaveAnswers(questionResponse);

                const currentTabCount = tabCount;
                tabCount = tabCount + 1;

                return (
                  <TabPanel
                    selectedTabIndex={selectedTabIndex}
                    index={currentTabCount}
                    key={`chapter-question-breakdown-${currentTabCount}`}
                    label="workplace-culture-chapter-question-breakdown-tab"
                  >
                    <QuestionBreakdown
                      questionBreakdown={questionResponse}
                      questionHasAnswers={questionHasAnswers}
                      selectedFilters={selectedFilters}
                      onFilterClick={filterClickHandler}
                    />
                  </TabPanel>
                );
              });
            })}
          </div>
        </div>
      )}
      {error && (
        <Typography desktop="h3">{t("chapterPage.breakdown.error")}</Typography>
      )}
    </div>
  );
};
