import { DataFilter } from "./filter";
import { SurveyQuestionResponse } from "./question";

export const BENCHMARKING_SCORE = 3.4;

export interface GetSurveyChapterScoringResponse {
  chapters: SurveyChapterScoring[];
}

export interface SurveyChapterScoring {
  id: string;
  description: string;
  name: string;
  image: string | undefined;
  demographic: GetSurveyScoringDemographicResponse;
}
export interface GetSurveyScoringDemographicResponse {
  id?: string;
  name?: string;
  scores: GetSurveyScoringResponse;
}

export interface GetSurveyScoringResponse {
  total_score?: number;
  filters: SurveyScoringFilter[];
}

export interface SurveyScoringFilter {
  label: string;
  id: string;
  groups: SurveyScoringGroup[];
  is_combined: boolean;
}

export interface SurveyScoringGroup {
  label: string;
  score?: number;
  id: string;
  is_scope: boolean;
  is_skip: boolean;
}
export interface GetQuestionBreakdownResponse {
  total_engagement: number;
  chapters: {
    id?: string;
    questions: QuestionBreakdown[];
  }[];
}

export interface QuestionBreakdown
  extends Omit<SurveyQuestionResponse, "options" | "order"> {
  filters: QuestionBreakdownFilter[]; // TODO: rename to demographic_groups
  total_responses: QuestionBreakdownGroup;
}

export interface QuestionBreakdownFilter {
  label: string;
  groups?: QuestionBreakdownGroup[]; // TODO: rename to demographics
  is_custom: boolean;
}

export interface QuestionBreakdownGroup {
  label: string;
  engagement: number;
  options: QuestionBreakdownOptions[];
  combined_options: QuestionBreakdownCombinedOptions[];
}
export interface QuestionBreakdownOptions {
  count: number | null;
  is_skip: boolean;
  reference_id: string;
  text: string;
}

export interface QuestionBreakdownCombinedOptions {
  count: number | null;
  is_skip: boolean;
  option_ids: string[];
  text: string;
}

export interface QuestionBreakdownFilterOptions {
  count: number | null;
  is_skip: boolean;
  option_ids?: string[];
  reference_id?: string;
  text: string;
}
export interface GetSurveyDemographicResponse {
  engagement: number;
  filters?: DataFilter<SurveyDemographicGroup>[];
}
export interface SurveyDemographicGroup {
  label: string;
  engagement?: number;
}

export interface AnswerDemographicGroup {
  label: string;
  count: number;
}

export interface SurveyChapterInsight {
  id: string;
  dispatch_id: string;
  chapter_id: string;
  insight: string;
  order: number;
  percentage: number;
  benchmark_percentage: number;
}

export type GetSurveyChapterInsightResponse = SurveyChapterInsight[];
