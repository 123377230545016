import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import useTranslation from "../../hooks/useTranslation";
import {
  QuestionBreakdownFilter,
  QuestionBreakdownGroup,
} from "../../types/workplaceCulture";
import { getQuestionResponseChartData } from "../../utils/QuestionBreakdown.utils";
import { QuestionBreakdownNoData } from "../QuestionBreakdownNoData/QuestionBreakdownNoData";

interface QuestionBreakdownChartProps {
  breakdownFilter: QuestionBreakdownFilter;
  useCombinedOptions?: boolean;
  showIsSkip?: boolean;
}

export const QuestionBreakdownChart = ({
  breakdownFilter,
  useCombinedOptions = true,
  showIsSkip = false,
}: QuestionBreakdownChartProps) => {
  const { t } = useTranslation("workplaceCulturePage");

  const breakdownFilterDidNotReachThreshold = breakdownFilter.groups?.every(
    (group: QuestionBreakdownGroup) =>
      group.options.every((option) => option.count === null)
  );

  const breakdownFilterHasNoAnswer = breakdownFilter.groups?.every(
    (group: QuestionBreakdownGroup) =>
      group.options.every((option) => option.count === 0)
  );

  if (breakdownFilterDidNotReachThreshold || breakdownFilterHasNoAnswer) {
    return (
      <QuestionBreakdownNoData
        breakdownFilter={breakdownFilter}
        customMessage={
          breakdownFilterHasNoAnswer
            ? t("surveyDemographics.noOneCheckedAnswer")
            : undefined
        }
      />
    );
  }

  const demographicsChartData = getQuestionResponseChartData(
    t,
    breakdownFilter,
    useCombinedOptions,
    showIsSkip
  );

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={demographicsChartData}
      containerProps={{
        "data-testid": "breakdown-chart-container",
      }}
    />
  );
};
